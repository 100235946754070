<template>
  <div class="page__pagination">
    <ul class="pagination" v-if="buttonsCount">
      <li class="page-item" @click="decrementPagination">
        <span class="page-link arrow"> ‹ </span>
      </li>
      <li v-for="(item, index) in pagesToShow" :key="index" class="page-item">
        <button
          type="button"
          :class="['page-link', { active: currentPagination === item }]"
          @click="$emit('paginationChanged', item)"
        >
          {{ item }}
        </button>
      </li>
      <li class="page-item" @click="incrementPagination">
        <span class="page-link arrow"> › </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "PaginationVue",
  props: {
    totalRows: {
      type: Number,
    },
    perMenu: {
      type: Number,
    },
    currentPagination: {
      type: Number,
      default: 1,
    },
    pagesOnScreen: {
      type: Number,
      default: 4
    }
  },
  data() {
    return {
      buttonsCount: 0,
    };
  },
  methods: {
    decrementPagination() {
      let decrementedPagination = JSON.parse(
        JSON.stringify(this.currentPagination)
      );
      decrementedPagination--;
      if (this.currentPagination > 1)
        this.$emit("paginationChanged", decrementedPagination);
    },
    incrementPagination() {
      let incrementedPagination = JSON.parse(
        JSON.stringify(this.currentPagination)
      );
      incrementedPagination++;
      if (this.currentPagination < this.buttonsCount)
        this.$emit("paginationChanged", incrementedPagination);
    },
  },
  watch: {
    perMenu(){
      this.buttonsCount = Math.ceil(this.totalRows / this.perMenu)
    },
    totalRows(){
      this.buttonsCount = Math.ceil(this.totalRows / this.perMenu)
    }
  },
  mounted(){
    this.buttonsCount = Math.ceil(this.totalRows / this.perMenu)
  },
  computed: {
    pagesToShow() {
      let result = [];

      if (!this.buttonsCount) return result;

      for (let pageNum = 1; pageNum <= this.buttonsCount; pageNum++) {
        if (result.length == this.pagesOnScreen) break;

        if (pageNum == this.currentPagination) result.push(pageNum);
        if (pageNum > this.currentPagination && pageNum < (this.currentPagination+this.pagesOnScreen)) result.push(pageNum);
        if (pageNum < this.currentPagination && pageNum > (this.currentPagination-this.pagesOnScreen+1)) result.push(pageNum);
      }

      return result;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.page__pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-link {
  color: $dark_text;
  background-color: transparent;
  border: none;
  // font-family: "Lato";
  font-weight: bold;
  font-size: 1rem;
  padding: 0.5rem 0.8rem;
  margin: 0 10px;
  border-radius: 3px;
  user-select: none;

  &:active,
  &:focus-visible,
  &:focus-within {
    outline: none;
    box-shadow: none !important;
  }

  &.active {
    color: $white;
    background-color: $purple;
  }

  &.arrow {
    cursor: pointer;
    color: $dark_text;
    background-color: transparent;
  }

  &:focus {
    outline: none;
  }
}
</style>
