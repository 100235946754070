<template>
  <Popup
    class="tag-list_popup"
    ref="mediaLibraryPopup"
    v-bind:custom-class="customClass"
    v-bind:modal-id="modalId"
    v-bind:onHide="closeModal"
    v-bind:show-popup="showPopup"
    v-bind:scrollable="true"
    v-bind:toggleStatus="true"
    v-bind:onClose="onClose"
    @close="onClose"
  >
    <template v-slot:header>
      {{ labels.select_media }}
    </template>

    <template v-slot:body>
      <div content-class="mt-3">
        <div class="buttons">
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a
                :class="[
                  'nav-link',
                  { active: selectedTab === 'mediaLibrary' },
                ]"
                @click="selectedTab = 'mediaLibrary'"
                >{{ tabsData.mediaLibrary.title }}</a
              >
            </li>
            <li class="nav-item">
              <a
                :class="['nav-link', { active: selectedTab === 'uploadFiles' }]"
                @click="selectedTab = 'uploadFiles'"
                >{{ tabsData.uploadFiles.title }}</a
              >
            </li>
          </ul>
        </div>

        <template v-if="selectedTab === 'mediaLibrary'">
          <div class="image-list mt-3">
            <div
              class="image-item"
              v-for="(item, index) in entityData"
              v-bind:key="index"
              v-on:click="addMedia(item.checked, index)"
            >
              <div
                ref="image"
                class="image"
                v-bind:class="{ checked: item.checked }"
                v-bind:style="setBackgroundImage(item.media)"
              ></div>
              <div class="checkbox" v-if="item.checked">
                <FormulateInput v-model="item.checked" type="checkbox" />
              </div>
              <p class="image-title">{{ setImageTitle(item.title) }}</p>
            </div>
          </div>
          <div
            v-if="
              Object.entries(pagination).length &&
              items.total / (items.limit || 20) > 1
            "
            class="overview__pagination"
          >
          <Pagination
            :total-rows="items.total"
            :per-menu="items.limit || 20"
            :current-pagination="pagination.current"
            @paginationChanged="paginationChanged"
          />
          </div>
        </template>
        <template v-else>
          <FileInput
            class="mt-3"
            v-bind:key="loopKey"
            v-bind:label="'Load media'"
            v-bind:value="value"
            v-bind:editable="editable"
            v-bind:url="typeof value === 'string' ? value : ''"
            v-bind:name="'Name'"
            v-on:file-changed="getFile($event)"
          />
        </template>

        <!-- </div> -->
      </div>
    </template>

    <template v-slot:footer>
      <Button
        variant="outline-secondary"
        class="footer-close-btn"
        :action="addImage"
        :label="'Add'"
      />
      <Button
        variant="outline-secondary"
        class="footer-close-btn"
        :label="'Close'"
        :action="onClose"
      />
    </template>
  </Popup>
</template>

<script>
import Popup from "@/components/Popups/Popup";
import FileInput from "@/components/Input/FileInput";
import Button from "@/components/Buttons/Button";
import Pagination from "@/components/Helpers/Pagination";

import { mapActions, mapState, mapGetters } from "vuex";

import prepareForm from "@/services/prepare-formdata";

export default {
  name: "MediaLibrary",
  components: {
    Popup,
    FileInput,
    Button,
    Pagination
  },
  props: {
    showPopup: {
      type: Boolean,
      default: false,
    },
    onHide: {
      type: Function,
      default: () => {},
    },
    modalId: {
      type: String,
      required: true,
    },
    customClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loopKey: Date.now(),
      entityData: [],
      pagination: {},
      items: {},
      value: "",
      editable: true,
      tabIndex: 0,
      selectedTab: "mediaLibrary",
      tabsData: {
        mediaLibrary: {
          title: "Media Library",
        },
        uploadFiles: {
          title: "Upload files",
        },
      },
    };
  },
  computed: {
    ...mapGetters("helper", ["labels"]),
    ...mapState("media", {
      // entityList: 'mediaList',
      permissions: "overviewPermissions",
    }),

    checkPagination() {
      return (
        Object.entries(this.pagination).length &&
        this.items.total / (this.items.limit || 20) > 1
      );
    },

    currentPage() {
      let page = 1;

      if (this.pagination && this.pagination.current) {
        if (this.items.total / (this.items.limit || 20) <= 1) {
          page = 1;
        } else {
          page = this.pagination.current;
        }
      } else if (this.$route.query.page) {
        page = this.$route.query.page;
      }
      return page;
    },
  },
  methods: {
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    ...mapActions("media", {
      getList: "getList",
    }),

    addMedia(checked, index) {
      let path = this.$route.path.split("?");
      window.history.pushState({}, null, path[0]);
      this.entityData[index].checked = !checked;
      this.$emit("get-media", this.entityData[index]);
      this.onClose();
      let element = document.querySelector(".modal-backdrop");
      if (element) {
        element.remove();
        document.querySelector("body").classList.remove("modal-open");
      }
    },

    getFile(data) {
      let title = data.name.split(".")[0] + "_" + Date.now();
      let reqData = {
        title: title,
        text: title, // Media requires title, let's set title as default description for this case because when user access this popup he wants to do upload as quick as possible
        media: data,
      };
      let self = this;
      this.$store
        .dispatch(`media/create`, prepareForm(reqData))
        .then((res) => {
          if (res.success) {
            self
              .getMediaList(1)
              .then((res) => {
                if (res.success) {
                  self.tabIndex = 0;
                  self.value = "";
                  self.loopKey = Date.now();
                  self.selectedTab = "mediaLibrary";
                  window.history.pushState(
                    {},
                    null,
                    `${self.$route.path}?media=library&page=1`
                  );
                }
              })
              .catch((err) => console.log(err));

            self.addNotification({
              variant: "success",
              msg: res.msg,
              labels: self.labels,
              dismissSecs: 5,
            });
          } else if (res.error) {
            self.addNotification({
              variant: "danger",
              msg: res.error,
              labels: self.labels,
              dismissSecs: 7,
            });
          }
        })
        .catch((err) => console.log(err));
    },

    addImage() {
      let list = [];
      this.entityData.forEach((item) => {
        if (item.checked) {
          list.push(item);
        }
      });
      this.onHide(list);
    },

    closeModal() {
      console.log("qwe");
      let path = this.$route.path.split("?");
      window.history.pushState({}, null, path[0]);
      this.onHide();
      this.$refs.mediaLibraryPopup.closePopup();
    },

    currentUserCan(action) {
      if (
        this.permissions &&
        this.permissions[action] &&
        this.permissions[action] === 1
      ) {
        return true;
      }
      return false;
    },

    setQueryPage() {
      if (
        !this.$route.query.page ||
        this.$route.query.page != this.currentPage
      ) {
        window.history.pushState(
          {},
          null,
          `${this.$route.path}?media=library&page=${this.currentPage}`
        );
      }
    },

    getMediaList(page) {
      let data = {
        page: this.currentPage,
      };
      if (page) data.page = page;

      if (this.$route.query.user) {
        data.user = true;
      }

      this.entityData = [];

      let self = this;
      return this.getList(data)
        .then((res) => {
          if (res.success) {
            res.data.data.forEach((item, index) => {
              let output = {
                _id: item._id,
                title: item.title,
                media: item.media,
                mediaType: item.mediaType,
                checked: false,
                link: `/dashboard/media/${item._id}`,
              };
              self.$set(self.entityData, [index], output);
            });
            self.items = { ...res.data.items };
            self.pagination = { ...res.data.pages };
          } else if (res.error) {
            self.addNotification({
              variant: "danger",
              msg: res.error,
              labels: self.labels,
            });
          }
          return res;
        })
        .catch((err) => console.log(err));
    },

    languageLoaderFn() {
      this.tabsData.mediaLibrary.title = this.labels.media_library;
      this.tabsData.uploadFiles.title = this.labels.upload_files;
    },

    resizeEvent() {
      if (this.$refs.image && this.$refs.image[0]) {
        let imageWidth = this.$refs.image[0].clientWidth;
        this.$refs.image.forEach((item) => {
          item.style.height = imageWidth + "px";
        });
      }
    },

    onClose() {
      this.$emit("close", false);
      let element = document.querySelector(".modal-backdrop");
      if (element) {
        element.remove();
        document.querySelector("body").classList.remove("modal-open");
      }
    },

    setBackgroundImage(media) {
      const image_extensions = ["png", "jpg", "jpeg", "svg", "gif"];
      const video_extensions = ["webm", "mp4", "mkv", "avi", "mov"];
      const audio_extensions = ["mp3", "flac", "aac", "aiff"];
      const splitedMedia = media.split(".");
      if (image_extensions.includes(splitedMedia[splitedMedia.length - 1])) {
        return { backgroundImage: `url(${media})` };
      } else if (
        video_extensions.includes(splitedMedia[splitedMedia.length - 1])
      ) {
        return {
          backgroundSize: "50%",
          backgroundImage: `url(https://cdn.pixabay.com/photo/2015/12/03/01/27/play-1073616_960_720.png)`,
        };
      } else if (
        audio_extensions.includes(splitedMedia[splitedMedia.length - 1])
      ) {
        return {
          backgroundSize: "50%",
          backgroundImage: `url(https://cdn.pixabay.com/photo/2017/02/27/20/48/headphones-2104207_960_720.png)`,
        };
      }
    },

    setImageTitle(title) {
      if (title.length < 36) {
        return title;
      }
      return title.substring(0, 35) + "...";
    },
    paginationChanged(page) {
      this.$router.replace({ name: this.$route.name, query: { ...this.$route.query, page: page } });
    }
  },
  mounted() {
    this.languageLoaderFn();
    this.getMediaList();
    this.setQueryPage();

    let count = 10;
    const getImageWidth = () => {
      if (!this.$refs.image && count > 0) {
        --count;
        return;
      }

      clearInterval(getImageWidthInterval);
      if (this.$refs.image) {
        let imageWidth = this.$refs.image[0].clientWidth;
        this.$refs.image.forEach((item) => {
          item.style.height = imageWidth + "px";
        });

        window.addEventListener("resize", this.resizeEvent);
      }
    };
    let getImageWidthInterval = setInterval(getImageWidth, 500);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeEvent);
  },
  watch: {
    tabIndex() {
      if (this.tabIndex === 1) {
        window.history.pushState({}, null, `${this.$route.path}?media=upload`);
      } else if (this.tabIndex === 0) {
        window.history.pushState(
          {},
          null,
          `${this.$route.path}?media=library&page=${this.currentPage}`
        );
      }
    },
    '$route.query.page'(newValue) {
      this.getMediaList(newValue);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.buttons {
  .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    .nav-tabs {
      border-bottom: 1px solid #dee2e6;
    }

    .nav-item {
      cursor: pointer;

      .nav-link {
        &.active {
          color: #495057;
          background-color: $white;
          border-color: #dee2e6 #dee2e6 $white;
        }
      }
    }
  }
}

.footer-close-btn {
  color: $black;
}

.image-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
}

.image-title {
  margin-top: 10px;
  margin-bottom: 0;
  word-break: break-all;
  text-align: center;
}

.image-item {
  position: relative;
  padding: 8px;
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;

  .image {
    cursor: pointer;
    border: 1px solid #bdc3c7;
    height: 160px;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    // background-size: 50%;

    &.checked {
      box-shadow: 0 0 0 1px #f0f0f1, 0 0 3px 5px #4f94d4;
    }
  }

  .checkbox {
    position: absolute;
    top: 0;
    right: 0;
  }

  @media (min-width: 360px) {
    flex: 0 0 50%;
    max-width: 50%;
    width: 100%;
  }

  @media (min-width: 500px) {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    width: 100%;
  }
  @media (min-width: 992px) {
    flex: 0 0 25%;
    max-width: 25%;
    width: 100%;
  }
}

::v-deep {
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
  .file-input_form.formulate-input {
    margin-right: 0;
  }

  .file-input_label {
    display: none;
  }

  .formulate-input-element--file {
    max-width: 100%;
  }

  .close {
    outline: none;
  }
}
</style>
